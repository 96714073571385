import React from "react";
import { useContext, useEffect, useState, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  DataTable,
  DataTableSkeleton,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Tile,
} from "carbon-components-react";
import { Delivery16, Fragile16, FruitBowl16 } from "@carbon/icons-react";
import {
  defaultPageSize,
  defaultPageSizeOptions,
  PuerperaWithBabyViewer,
} from "../../components/Viewer";

import { Add16, Edit16, View16 } from "@carbon/icons-react";

import dayjs from "dayjs";
import useSWR from "swr";
import { DateTimeFormat } from "../../utils/datetime";
import { UserContext } from "../../context";
import { URL, Fetcher as F } from "../../api";
import { useQuery } from "../../hooks";
import { hasId } from "../../utils";

const headers = [
  {
    key: "record",
    name: "日期時間",
  },
  {
    key: "milk_mon",
    name: "母奶",
  },
  {
    key: "milk_formula",
    name: "配方",
  },
  {
    key: "mom_baby_start",
    name: "開始",
  },
  {
    key: "mom_baby_end",
    name: "結束",
  },
  {
    key: "breast_feeding",
    name: "親餵",
  },
  {
    key: "milk_start",
    name: "開始",
  },
  {
    key: "milk_end",
    name: "結束",
  },
  {
    key: "urinate",
    name: "小便",
  },
  {
    key: "stool",
    name: "大便",
  },
  {
    key: "rr",
    name: "呼吸",
  },
  {
    key: "hr",
    name: "心跳",
  },
  {
    key: "temperature",
    name: "體溫",
  },
  {
    key: "weight",
    name: "體重",
  },
  {
    key: "guts",
    name: "膽色素",
  },
  {
    key: "edit",
    name: "編輯",
  },
];

const createQueryString = ({ offset, limit, babyID }) => {
  const params = new URLSearchParams();
  if (offset !== undefined) {
    params.append("offset", offset);
  }
  if (limit !== undefined) {
    params.append("limit", limit);
  }
  if (babyID !== undefined) {
    params.append("baby", babyID);
  }
  return params.toString();
};

export const BabyWithBabyStatusInfo = () => {
  const { bid: babyID } = useParams();

  const q = useQuery();
  const date = q.get("_date");

  const {
    token: { access },
  } = useContext(UserContext);
  const canFetch = access && !!babyID;

  const [brsDate, setBrsDate] = useState("");

  useEffect(() => {
    if (date !== null) {
      setBrsDate(date);
    } else {
      const today = dayjs().format(DateTimeFormat.date);
      setBrsDate(today);
    }
  }, [date, setBrsDate]);

  const expand = "puerpera,puerperaNote,bookroom.contract,";
  const babyURL = `${URL.baby}${babyID}/?expand=${expand}&brs_in_date=${brsDate}`;
  const { data: babyData } = useSWR(canFetch && [babyURL], F.withToken);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultPageSize);
  const offset = (page - 1) * limit;

  const queryString = useMemo(
    () =>
      createQueryString({
        offset,
        limit,
        babyID,
      }),
    [offset, limit, babyID]
  );

  const babyStatusURL = `${URL.babyStatus}?${queryString}`;
  const { data: statusRecordData } = useSWR(
    canFetch && [babyStatusURL],
    F.withToken
  );

  const isLoading = !babyData;
  const isLoadingStatusRecord = !statusRecordData;

  return (
    <Content className="baby-with-baby-status-info">
      <Breadcrumb>
        <BreadcrumbItem href="/care">護理管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          新生兒護理明細
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>新生兒護理明細</Tile>

      <Grid>
        <Column sm={2} md={{ span: 4, offset: 2 }}>
          <Button
            as={Link}
            to={`/care/${babyID}/babyLeave`}
            kind="secondary"
            size="small"
            renderIcon={Delivery16}
          >
            新生兒離館紀錄
          </Button>
          <Button
            as={Link}
            to={`/care/${babyID}/babyUnusual`}
            kind="secondary"
            size="small"
            renderIcon={Fragile16}
          >
            新生兒異常紀錄
          </Button>
          <Button
            as={Link}
            to={`/care/${babyID}/puerperaLeave`}
            kind="secondary"
            size="small"
            renderIcon={FruitBowl16}
          >
            產婦離館紀錄
          </Button>
        </Column>
      </Grid>

      <Grid>
        <Column>
          <PuerperaWithBabyViewer loading={isLoading} data={babyData} />
          {isLoadingStatusRecord ? (
            <DataTableSkeleton showHeader={false} />
          ) : (
            <BabyStatusRecordTable
              page={page}
              offset={offset}
              limit={limit}
              babyID={babyID}
              data={statusRecordData}
              onPageChange={({ page, pageSize }) => {
                setPage(page);
                setLimit(pageSize);
              }}
            />
          )}
        </Column>
      </Grid>
    </Content>
  );
};

function BabyStatusRecordTable({ page, limit, babyID, data, onPageChange }) {
  const pageSize = limit;
  const total = (data && data.count) || 0;
  const _data = (data && data.results) || [];

  const cleanData = _data;

  const pageProps = {
    disabled: !data,
    page: page,
    totalItems: total,
    backwardText: "前一頁",
    forwardText: "下一頁",
    pageSize: pageSize,
    pageSizes: defaultPageSizeOptions,
    itemsPerPageText: "顯示資料數：",
    onChange: onPageChange,
  };

  return (
    <DataTable rows={cleanData} headers={headers}>
      {({
        rows,
        headers,
        getHeaderProps,
        getExpandHeaderProps,
        getRowProps,
        getTableProps,
        getToolbarProps,
        getTableContainerProps,
      }) => (
        <TableContainer {...getTableContainerProps()}>
          <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
            <TableToolbarContent>
              <Button
                renderIcon={View16}
                as={Link}
                to={`/care/${babyID}/summary`}
              >
                新生兒每日記錄
              </Button>
              <Button
                renderIcon={Add16}
                as={Link}
                to={`/care/${babyID}/createBabyStatus`}
              >
                新增新生兒記錄
              </Button>
            </TableToolbarContent>
          </TableToolbar>

          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                <TableExpandHeader
                  enableExpando={true}
                  {...getExpandHeaderProps()}
                />
                {headers.map((header, i) => (
                  <TableHeader key={i} {...getHeaderProps({ header })}>
                    {header.name}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                const rowData = cleanData.find(hasId(row.id));
                return (
                  <React.Fragment key={row.id}>
                    <TableExpandRow {...getRowProps({ row })}>
                      {row.cells.map((cell) => {
                        const { header = "" } = cell && cell.info;
                        const id = cell.id.split(":")[0];
                        let content;
                        switch (header) {
                          case "record":
                            if (cell.value !== null) {
                              content = dayjs(cell.value).format(
                                DateTimeFormat.datetime
                              );
                            } else {
                              content = cell.value;
                            }
                            break;
                          case "mom_baby_start":
                          case "mom_baby_end":
                          case "milk_start":
                          case "milk_end":
                            if (cell.value !== null) {
                              content = dayjs(cell.value).format(
                                DateTimeFormat.time
                              );
                            } else {
                              content = cell.value;
                            }
                            break;
                          case "breast_feeding":
                            content = cell.value ? "是" : "否";
                            break;
                          case "milk_mon":
                          case "milk_formula":
                          case "urinate":
                          case "stool":
                          case "rr":
                          case "hr":
                          case "temperature":
                          case "weight":
                          case "guts":
                            content = cell.value;
                            break;
                          case "edit":
                            content = (
                              <Button
                                kind="ghost"
                                renderIcon={Edit16}
                                iconDescription="編輯"
                                as={Link}
                                to={`/care/babyStatus/${id}/edit`}
                              />
                            );
                            break;
                          default:
                            content = <span>?</span>;
                            break;
                        }
                        return <TableCell key={cell.id}>{content}</TableCell>;
                      })}
                    </TableExpandRow>
                    <TableExpandedRow
                      colSpan={headers.length + 1}
                      key={`${row.id}__expanded`}
                    >
                      <p>備註： {rowData && rowData.comment}</p>
                    </TableExpandedRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>

          <Pagination {...pageProps} />
        </TableContainer>
      )}
    </DataTable>
  );
}
