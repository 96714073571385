import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Content,
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Tile,
  Modal,
} from "carbon-components-react";
import { Add16, Edit20 } from "@carbon/icons-react";
import dayjs from "dayjs";
import useSWR, { useSWRConfig } from "swr";

import { URL, Fetcher as F } from "../../api";
import { DateTimeFormat } from "../../utils/datetime";
import { SchemaContext, UserContext } from "../../context";
import { SchemaEditor } from "../../components/Editor";
import { useUserData } from "../../hooks";

const headers = [
  {
    key: "category",
    name: "類別",
  },
  {
    key: "name",
    name: "贈品名稱",
  },
  {
    key: "cost_price",
    name: "成本",
  },
  {
    key: "selling_price",
    name: "售價",
  },
  {
    key: "unit",
    name: "單位",
  },
  {
    key: "active",
    name: "狀態",
  },
  {
    key: "actions",
    name: "編輯",
  },
];

export const FreeGift = () => {
  const { hid } = useParams();

  const {
    token: { access },
  } = useContext(UserContext);

  const url = `${URL.freegift}?expand=category,unit`;
  const { data: ccData = [] } = useSWR(access && [url], F.withToken);

  const isLoading = !ccData;

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/">系統管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          贈品
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>贈品管理</Tile>

      {isLoading ? (
        <DataTableSkeleton showHeader={false} />
      ) : (
        <FreeGiftTable housing={hid} items={ccData} />
      )}
    </Content>
  );
};

function FreeGiftTable({ housing, items }) {
  const { freegift: schema } = useContext(SchemaContext) || {};
  const {
    token: { access },
  } = useContext(UserContext);
  const user = useUserData();
  const { mutate } = useSWRConfig();

  const cleanData = items.map(({ category, unit, status, ...other }) => {
    return {
      ...other,
      category: category.name,
      unit: unit.name,
      active: status ? "✅" : "❌",
    };
  });
  const [freegift, setFreegift] = useState();

  const [currentHistoryID, setCurrentHistoryID] = useState("");

  const [isEditHistory, setIsEditHistory] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editModalStatus, setEditModalStatus] = useState("inactive");

  const isEditTitle = isEditHistory ? "編輯" : "新增";

  const handleCreate = async () => {
    const { id, ...o } = freegift;
    const payload = { ...o };

    setEditModalStatus("active");
    await F.post(`${URL.freegift}`, payload);
    mutate([URL.freegift]);
    setOpenEditModal(false);
  };

  const ccURL = `${URL.freegift}${currentHistoryID}`;
  const { data: rawData } = useSWR(
    isEditHistory ? access && [ccURL] : null,
    F.withToken
  );

  useEffect(() => {
    if (!rawData || rawData == null) {
      return;
    }
    setFreegift(rawData);
  }, [rawData]);

  const handleEdit = async () => {
    if (currentHistoryID === 0 || !rawData) {
      return;
    }

    const { id, ...o } = freegift;
    const payload = { ...o };

    setEditModalStatus("active");
    const r = await F.put(`${URL.freegift}${currentHistoryID}/`, payload);
    mutate([URL.freegift + r.id], r, false);
    setOpenEditModal(false);
  };

  const handleCreateOrEdit = async () => {
    isEditHistory ? handleEdit() : handleCreate();
  };

  return (
    <>
      <Modal
        open={openEditModal}
        modalHeading={`${isEditTitle}贈品`}
        primaryButtonText="確認"
        secondaryButtonText="取消"
        loadingStatus={editModalStatus}
        onRequestClose={() => setOpenEditModal(false)}
        onSecondarySubmit={() => setOpenEditModal(false)}
        onRequestSubmit={handleCreateOrEdit}
      >
        <SchemaEditor
          fields={schema.fields}
          excludes={[
            "id",
            "created",
            "modified",
            "report_employee_id",
            "housing",
            "activate_date",
            "deactivate_date",
            "status",
            "center",
            "complaints_file" /* TBD */,
          ]}
          requiredFields={["report_date", "reason"]}
          data={freegift}
          onChange={(x) => {
            if (!user) return;
            setFreegift({
              ...x,
              housing: housing,
              report_employee_id: user.employee_id,
            });
          }}
        />
      </Modal>
      <DataTable rows={cleanData} headers={headers}>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          getToolbarProps,
          getTableContainerProps,
        }) => (
          <TableContainer {...getTableContainerProps()}>
            <TableToolbar
              {...getToolbarProps()}
              aria-label="data table toolbar"
            >
              <TableToolbarContent>
                <Button
                  renderIcon={Add16}
                  onClick={() => {
                    setIsEditHistory(false);
                    setOpenEditModal(true);
                  }}
                >
                  新增贈品
                </Button>
              </TableToolbarContent>
            </TableToolbar>

            <Table {...getTableProps()} isSortable>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({ header })}
                      isSortable={header.key !== "actions"}
                    >
                      {header.name}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      const { header = "" } = cell && cell.info;
                      const id = cell.id.split(":")[0];
                      let content;
                      switch (header) {
                        case "created":
                        case "modified":
                          if (cell.value !== null) {
                            content = dayjs(cell.value).format(
                              DateTimeFormat.date
                            );
                          } else {
                            content = cell.value;
                          }
                          break;
                        case "name":
                        case "category":
                        case "unit":
                        case "cost_price":
                        case "selling_price":
                        case "active":
                          content = cell.value;
                          break;
                        case "actions":
                          content = (
                            <Button
                              kind="ghost"
                              renderIcon={Edit20}
                              iconDescription="編輯"
                              onFocus={() => setCurrentHistoryID(id)}
                              onClick={() => {
                                setIsEditHistory(true);
                                setOpenEditModal(true);
                              }}
                            />
                          );
                          break;
                        default:
                          content = <span>?</span>;
                      }
                      return <TableCell key={cell.id}>{content}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </>
  );
}
