import { createContext } from "react";

export const UserContext = createContext({
  token: {},
  login: () => {},
  logout: () => {},
});

export const CentersContext = createContext({
  current: {},
  setCurrent: () => {},
  list: [],
});

export const RoomTypeContext = createContext({
  list: undefined,
});

export const DepartmentsContext = createContext({
  list: [],
});

export const SchemaContext = createContext({
  bookVisitNote: undefined,
  bookVisit: undefined,
  bookVisitSchedule: undefined,
  customerBookVisit: undefined,
  bookRoom: undefined,
  bookRoomSchedule: undefined,
  customer: undefined,
  staff: undefined,
  housing: undefined,
  housingEvent: undefined,
  contract: undefined,
  puerpera: undefined,
  changeFoodVendorHistory: undefined,
  beverageHistory: undefined,
  orderBeverage: undefined,
  freegift: undefined,
});

export const StatusContext = createContext({
  bookVisitStatus: [],
  customerBookVisitStatus: [],
  interactionStatus: [],
  changeFoodVendorReason: [],
  foodMeals: [],
  foodVendor: [],
  complaintsReason: [],
  leaveReason: [],
  babyUnusualReason: [],
  freegiftUnit: [],
  freegiftCategory: [],
  deadOrderStatus: [],
});
