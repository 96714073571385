import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Button,
} from "carbon-components-react";
import { Add20, DocumentExport20, RequestQuote20 } from "@carbon/icons-react";
import dayjs from "dayjs";

import { DateTimeFormat } from "../../utils/datetime";
import { usePuerpera, useContractList } from "../../hooks";
import { PuerperaRow } from "../../components/Viewer";

const headers = [
  {
    key: "modified",
    header: "修改日期",
  },
  {
    key: "sign",
    header: "簽約日期",
  },
  {
    key: "terminate_date",
    header: "退訂日期",
  },
  {
    key: "contract_number",
    header: "合約編號",
  },
  {
    key: "center",
    header: "館別",
  },
  {
    key: "note",
    header: "備註",
  },
  {
    key: "file",
    header: "檔案",
  },
  {
    key: "actions",
    header: "編輯",
  },
];

export const ContractTab = ({ pid }) => {
  const puerpera = usePuerpera(pid);
  const contractList = useContractList({ puerpera: pid });
  const [cherieLink, setCherieLink] = useState("#");

  useEffect(() => {
    if (!puerpera || puerpera == null) {
      return;
    }
    // TODO: move this flow to backend
    const checkID = "eipSystem";
    const contract_number =
      (puerpera.last_contract !== null &&
        puerpera.last_contract?.contract_number) ??
      "";
    if (puerpera.name !== null && contract_number !== "") {
      setCherieLink(
        `https://docs.google.com/forms/d/e/1FAIpQLSfZndN3dps8yJNVbH8T6sTvGwJ0wDXDZl_V6UxmUKxWF9i8Tw/viewform?usp=pp_url&entry.150370803=${contract_number}&entry.956600363=${checkID}&entry.895260898=${puerpera.name}`
      );
    }
  }, [puerpera, setCherieLink]);

  return (
    <div>
      <PuerperaRow showContract={true} data={puerpera ?? {}}>
        <Button as="a" target="_blank" href={cherieLink}>
          贈品表格
        </Button>
        <Button renderIcon={Add20} as={Link} to="create">
          新增合約
        </Button>
      </PuerperaRow>
      <DataTable rows={contractList} headers={headers}>
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()} isSortable>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader
                    {...getHeaderProps({ header })}
                    isSortable={header.key === "sign"}
                  >
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow {...getRowProps({ row })}>
                  {row.cells.map((cell) => {
                    const type = cell.info.header;
                    let content;
                    switch (type) {
                      case "modified":
                        content = cell.value
                          ? dayjs(cell.value).format(DateTimeFormat.datetime)
                          : "";
                        break;
                      case "sign":
                      case "terminate_date":
                        content = cell.value
                          ? dayjs(cell.value).format(DateTimeFormat.date)
                          : "";
                        break;
                      case "center":
                        content = cell.value?.name;
                        break;
                      case "file":
                        content = (
                          <Button
                            kind="ghost"
                            renderIcon={DocumentExport20}
                            as="a"
                            href={cell.value}
                          />
                        );
                        break;
                      case "actions":
                        content = (
                          <Button
                            kind="ghost"
                            renderIcon={RequestQuote20}
                            as={Link}
                            to={`../contractEdit/${row.id}`}
                          />
                        );
                        break;
                      default:
                        content = cell.value;
                        break;
                    }
                    return <TableCell key={`${cell.id}`}>{content}</TableCell>;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DataTable>
    </div>
  );
};
