import { useContext } from "react";
import useSWR from "swr";

import { UserContext } from "../context";
import { URL, Fetcher as F } from "../api";

export const useCenters = () => {
  const {
    token: { access },
  } = useContext(UserContext);
  const url = `${URL.center}?is_show=true`;
  const { data: centerList = [] } = useSWR(access && [url], F.withToken, {
    revalidateOnFocus: false,
  });

  return centerList;
};
