import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Content,
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Tile,
  Modal,
} from "carbon-components-react";
import { Add16, Edit20 } from "@carbon/icons-react";
import dayjs from "dayjs";
import useSWR, { useSWRConfig } from "swr";

import { URL, Fetcher as F } from "../../api";
import { DateTimeFormat } from "../../utils/datetime";
import { SchemaContext, UserContext } from "../../context";
import { SchemaEditor } from "../../components/Editor";
import { useUserData, usePuerperaAndCenter } from "../../hooks";

const headers = [
  {
    key: "report_date",
    name: "發生日期",
  },
  {
    key: "compensation_date",
    name: "賠償日期",
  },
  {
    key: "reason_name",
    name: "客訴原因",
  },
  {
    key: "center_name",
    name: "館別",
  },
  {
    key: "employee_name",
    name: "操作人員",
  },
  {
    key: "actions",
    name: "編輯/取消",
  },
];

export const CustomerComplaintsHistory = () => {
  const { hid } = useParams();

  const {
    token: { access },
  } = useContext(UserContext);

  const [puerpera, puerperaDisplay] = usePuerperaAndCenter({
    housing: hid,
  });

  const ccURL = `${URL.customerComplaints}?expand=report_employee,center,reason&housing=${hid}`;
  const { data: ccData = [] } = useSWR(access && [ccURL], F.withToken);

  const isLoading = !ccData && !puerpera;

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/housing/">住房管理</BreadcrumbItem>
        <BreadcrumbItem href={`/housing/${hid}`}>
          {puerperaDisplay}
        </BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          客訴記錄
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>客訴記錄</Tile>

      {isLoading ? (
        <DataTableSkeleton showHeader={false} />
      ) : (
        <CustomerComplaintsHistoryTable housing={hid} items={ccData} />
      )}
    </Content>
  );
};

function CustomerComplaintsHistoryTable({ housing, items }) {
  const { customerComplaints: schema } = useContext(SchemaContext) || {};
  const {
    token: { access },
  } = useContext(UserContext);
  const user = useUserData();
  const { mutate } = useSWRConfig();

  const cleanData = items.map(
    ({ report_employee, center, reason, ...other }) => {
      return {
        ...other,
        employee_name: report_employee?.name ?? "-",
        center_name: center?.name ?? "-",
        reason_name: reason?.name ?? "-",
      };
    }
  );
  const [customerComplaints, setCustomerComplaints] = useState();

  const [currentHistoryID, setCurrentHistoryID] = useState("");

  const [isEditHistory, setIsEditHistory] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editModalStatus, setEditModalStatus] = useState("inactive");

  const isEditTitle = isEditHistory ? "編輯" : "新增";

  const handleCreate = async () => {
    const { id, ...o } = customerComplaints;
    const payload = { ...o };

    setEditModalStatus("active");
    await F.post(`${URL.customerComplaints}`, payload);
    mutate([URL.customerComplaints]);
    setOpenEditModal(false);
  };

  const ccURL = `${URL.customerComplaints}${currentHistoryID}`;
  const { data: rawCustomerComplaintsData } = useSWR(
    isEditHistory ? access && [ccURL] : null,
    F.withToken
  );

  useEffect(() => {
    if (!rawCustomerComplaintsData || rawCustomerComplaintsData == null) {
      return;
    }
    setCustomerComplaints(rawCustomerComplaintsData);
  }, [rawCustomerComplaintsData]);

  const handleEdit = async () => {
    if (currentHistoryID === 0 || !rawCustomerComplaintsData) {
      return;
    }

    const { id, ...o } = customerComplaints;
    const payload = { ...o };

    setEditModalStatus("active");
    const r = await F.put(
      `${URL.customerComplaints}${currentHistoryID}/`,
      payload
    );
    mutate([URL.customerComplaints + r.id], r, false);
    setOpenEditModal(false);
  };

  const handleCreateOrEdit = async () => {
    isEditHistory ? handleEdit() : handleCreate();
  };

  return (
    <>
      <Modal
        open={openEditModal}
        modalHeading={`${isEditTitle}客訴`}
        primaryButtonText="確認"
        secondaryButtonText="取消"
        loadingStatus={editModalStatus}
        onRequestClose={() => setOpenEditModal(false)}
        onSecondarySubmit={() => setOpenEditModal(false)}
        onRequestSubmit={handleCreateOrEdit}
      >
        <SchemaEditor
          fields={schema.fields}
          excludes={[
            "id",
            "created",
            "modified",
            "report_employee_id",
            "housing",
            "activate_date",
            "deactivate_date",
            "status",
            "center",
            "complaints_file" /* TBD */,
          ]}
          requiredFields={["report_date", "reason"]}
          data={customerComplaints}
          onChange={(x) => {
            if (!user) return;
            setCustomerComplaints({
              ...x,
              housing: housing,
              report_employee_id: user.employee_id,
            });
          }}
        />
      </Modal>
      <DataTable rows={cleanData} headers={headers}>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          getToolbarProps,
          getTableContainerProps,
        }) => (
          <TableContainer {...getTableContainerProps()}>
            <TableToolbar
              {...getToolbarProps()}
              aria-label="data table toolbar"
            >
              <TableToolbarContent>
                <Button
                  renderIcon={Add16}
                  onClick={() => {
                    setIsEditHistory(false);
                    setOpenEditModal(true);
                  }}
                >
                  新增客訴
                </Button>
              </TableToolbarContent>
            </TableToolbar>

            <Table {...getTableProps()} isSortable>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({ header })}
                      isSortable={header.key !== "actions"}
                    >
                      {header.name}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      const { header = "" } = cell && cell.info;
                      const id = cell.id.split(":")[0];
                      let content;
                      switch (header) {
                        case "report_date":
                        case "compensation_date":
                        case "created":
                        case "modified":
                          if (cell.value !== null) {
                            content = dayjs(cell.value).format(
                              DateTimeFormat.date
                            );
                          } else {
                            content = cell.value;
                          }
                          break;
                        case "employee_name":
                        case "center_name":
                        case "reason_name":
                          content = cell.value;
                          break;
                        case "actions":
                          content = (
                            <Button
                              kind="ghost"
                              renderIcon={Edit20}
                              iconDescription="編輯"
                              onFocus={() => setCurrentHistoryID(id)}
                              onClick={() => {
                                setIsEditHistory(true);
                                setOpenEditModal(true);
                              }}
                            />
                          );
                          break;
                        default:
                          content = <span>?</span>;
                      }
                      return <TableCell key={cell.id}>{content}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </>
  );
}
