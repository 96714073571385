import { Link, useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  Grid,
  Row,
  Tile,
} from "carbon-components-react";
import {
  Delivery16,
  Fragile16,
  FruitBowl16,
  Monster16,
  RequestQuote16,
  Undo16,
} from "@carbon/icons-react";
import { HousingViewer } from "../../components/Viewer";

import useSWR from "swr";

import { URL, Fetcher as F } from "../../api";

export const HousingInfo = () => {
  const { hid } = useParams();

  const expand =
    "events,foodvendor,bookRoom.puerpera,puerperaNote," +
    "bookRoom.contract,bookRoom.first_brs,bookRoom.latest_brs.room.center,";

  const housingURL = `${URL.housing}${hid}/?expand=${expand}`;
  const { data } = useSWR([housingURL], F.withToken);

  const isLoading = !data;

  return (
    <Content className="housing-info">
      <Breadcrumb>
        <BreadcrumbItem href="/housing">住房管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          瀏覽
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>瀏覽住房狀態</Tile>

      <Grid>
        <Column sm={2} md={{ span: 4, offset: 2 }}>
          <Button
            as={Link}
            to={`/housing/foodVendor/${hid}`}
            kind="secondary"
            size="small"
            renderIcon={Delivery16}
          >
            更換膳食廠商
          </Button>
          <Button
            as={Link}
            to={`/housing/${hid}/beverage`}
            kind="secondary"
            size="small"
            renderIcon={Fragile16}
          >
            更換飲料
          </Button>
          <Button
            as={Link}
            to={`/housing/${hid}/stopMeals`}
            kind="secondary"
            size="small"
            renderIcon={FruitBowl16}
          >
            預約停餐
          </Button>
          <Button
            as={Link}
            to={`/housing/${hid}/complaintsHistory`}
            kind="secondary"
            size="small"
            renderIcon={Monster16}
          >
            客訴紀錄
          </Button>
        </Column>
      </Grid>

      <Grid>
        <Column>
          <HousingViewer loading={isLoading} data={data} />

          <Row>
            <Column sm={{ span: 1, offset: 1 }}>
              <Button
                as={Link}
                to={`/housing/${hid}/edit`}
                kind="primary"
                size="small"
                renderIcon={RequestQuote16}
              >
                編輯
              </Button>
            </Column>
            <Column>
              <Button
                as={Link}
                to="/housing/"
                kind="secondary"
                size="small"
                renderIcon={Undo16}
              >
                返回住房管理
              </Button>
            </Column>
          </Row>
        </Column>
        <Column sm={1} />
      </Grid>
    </Content>
  );
};
