import { useContext, useState } from "react";
import { Dropdown, DropdownSkeleton } from "carbon-components-react";
import { Label } from "../Label";
import { StatusContext } from "../../../context";

export const FreeGiftCategory = ({
  id,
  className,
  style,
  field = {},
  data,
  onChange,
}) => {
  const { freegiftCategory } = useContext(StatusContext);
  const title = "贈品類別";

  const { key, required = false } = field;
  const { type = "number" } = field.schema;
  const item = (freegiftCategory || []).find((x) => x.id === data);
  const [shouldValidate, setShouldValidate] = useState(false);
  const invalid = shouldValidate && required && data === undefined;

  return !freegiftCategory ? (
    <DropdownSkeleton id={id} className={className} style={style} />
  ) : (
    <Dropdown
      id={id}
      className={className}
      style={style}
      titleText={<Label required={required}>{title}</Label>}
      label="請選擇"
      items={freegiftCategory}
      itemToString={(item) => (item ? item.name : "")}
      selectedItem={item}
      invalid={invalid}
      invalidText="此為必填欄位"
      onFocus={() => setShouldValidate(false)}
      onBlur={() => setShouldValidate(true)}
      onChange={(e) => {
        let v = e.selectedItem.id;
        if (type === "number") {
          v = +v;
        }
        if (typeof onChange !== "function") return;
        onChange({ [key]: v });
      }}
    />
  );
};
