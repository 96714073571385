import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  Header,
  HeaderContainer,
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderMenu,
  HeaderMenuItem,
  HeaderName,
  HeaderNavigation,
  HeaderPanel,
  SkipToContent,
  Switcher,
  SwitcherDivider,
  SwitcherItem,
} from "carbon-components-react";
import {
  Home20,
  NotificationFilled20,
  Switcher20,
  UserFilled20,
} from "@carbon/icons-react";

import { UserContext } from "../../context";
import { CentersContext } from "../../context";
import { useUserData } from "../../hooks";

function PageSwitcher({ user, onClickSideNavExpand }) {
  let pages = [
    {
      path: "room-activity",
      name: "五日動態",
      appendDivider: true,
      view_key: "v_days_activity",
    },
    {
      path: "bookvisit",
      name: "預約參訪",
      appendDivider: true,
      view_key: "v_bookvisit",
    },
    {
      path: "bookvisit/customer",
      name: "網路預約",
      appendDivider: true,
      view_key: "v_bookvisit_customer",
    },
    {
      path: "puerpera",
      name: "產婦管理",
      appendDivider: true,
      view_key: "v_puerpera",
    },
    {
      path: "bookroom",
      name: "訂房排房",
      appendDivider: true,
      view_key: "v_book",
    },
    {
      path: "housing",
      name: "住房管理",
      appendDivider: true,
      view_key: "v_housing",
    },
    {
      path: "care",
      name: "護理管理",
      appendDivider: true,
      view_key: "v_baby",
    },
    {
      path: "room",
      name: "房務管理",
      appendDivider: false,
      view_key: "v_manager",
    },
    {
      path: "staff",
      name: "員工管理",
      appendDivider: false,
      view_key: "v_manager",
    },
    {
      path: "group",
      name: "權限管理",
      appendDivider: true,
      view_key: "v_manager",
    },
  ];

  const navigate = useNavigate();

  return !user ? (
    <></>
  ) : (
    pages.map((p) => {
      return user[p.view_key] ? (
        <>
          {
            <SwitcherItem
              key={`switch_page_${p.path}`}
              aria-label={`${p.name}`}
              onClick={() => {
                navigate(`/${p.path}`);
                onClickSideNavExpand();
              }}
            >
              {`${p.name}`}
            </SwitcherItem>
          }
          {p.appendDivider && <SwitcherDivider />}
        </>
      ) : (
        <></>
      );
    })
  );
}

// eslint-disable-next-line
function CenterSwitcher({ onClickSideNavExpand, centers }) {
  const { setCurrent } = useContext(CentersContext);
  if (!centers.length) {
    return <div></div>;
  }

  return centers.map((c) => (
    <SwitcherItem
      key={`switch_center_${c.id}`}
      aria-label={`${c.name}`}
      onClick={() => {
        setCurrent(c);
        onClickSideNavExpand();
      }}
    >
      {c.name}
    </SwitcherItem>
  ));
}

export const CherieHeader = () => {
  const navigate = useNavigate();

  const { token } = useContext(UserContext);
  const { current } = useContext(CentersContext);

  const isUserLogin = !!token.access;
  const user = useUserData();

  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label="Cherie header">
          <SkipToContent />

          <HeaderName to="/" element={Link} prefix="璽悅資訊系統">
            {current && current.name ? `[${current.name}]` : ""}
          </HeaderName>

          {isUserLogin && !!user ? (
            <HeaderNavigation aria-label="導覽列">
              {user.v_days_activity && (
                <HeaderMenuItem
                  to="/room-activity"
                  element={Link}
                  aria-label="五日動態"
                >
                  五日動態
                </HeaderMenuItem>
              )}
              {user.v_bookvisit && (
                <HeaderMenuItem
                  to="/bookvisit"
                  element={Link}
                  aria-label="預約參訪"
                >
                  預約參訪
                </HeaderMenuItem>
              )}
              {user.v_bookvisit_customer && (
                <HeaderMenuItem
                  to="/bookvisit/customer"
                  element={Link}
                  aria-label="網路預約"
                >
                  網路預約
                </HeaderMenuItem>
              )}
              {user.v_puerpera && (
                <HeaderMenuItem
                  to="/puerpera"
                  element={Link}
                  aria-label="產婦管理"
                >
                  產婦管理
                </HeaderMenuItem>
              )}
              {user.v_book && (
                <HeaderMenuItem
                  to="/bookroom"
                  element={Link}
                  aria-label="訂房排房"
                >
                  訂房排房
                </HeaderMenuItem>
              )}
              {user.v_housing && (
                <HeaderMenuItem
                  to="/housing"
                  element={Link}
                  aria-label="住房管理"
                >
                  住房管理
                </HeaderMenuItem>
              )}
              {user.v_housing && (
                <HeaderMenuItem
                  to="/foodvendor"
                  element={Link}
                  aria-label="膳食統計"
                >
                  膳食統計
                </HeaderMenuItem>
              )}
              {user.v_baby && (
                <HeaderMenuItem to="/care" element={Link} aria-label="護理管理">
                  護理管理
                </HeaderMenuItem>
              )}
              {user.v_manager && (
                <HeaderMenu aria-label="系統" menuLinkName="系統">
                  <HeaderMenuItem
                    to="/room"
                    element={Link}
                    aria-label="房務管理"
                  >
                    房務管理
                  </HeaderMenuItem>
                  <HeaderMenuItem
                    to="/staff"
                    element={Link}
                    aria-label="員工管理"
                  >
                    員工管理
                  </HeaderMenuItem>
                  <HeaderMenuItem
                    to="/group"
                    element={Link}
                    aria-label="權限管理"
                  >
                    權限管理
                  </HeaderMenuItem>
                  <HeaderMenuItem
                    to="/freegift"
                    element={Link}
                    aria-label="贈品管理"
                  >
                    贈品管理
                  </HeaderMenuItem>
                </HeaderMenu>
              )}
            </HeaderNavigation>
          ) : (
            <HeaderNavigation aria-label="Login Navigation">
              <HeaderMenuItem to="/login" element={Link}>
                登入
              </HeaderMenuItem>
            </HeaderNavigation>
          )}

          {isUserLogin && (
            <HeaderGlobalBar>
              <HeaderGlobalAction
                aria-label="首頁"
                onClick={() => {
                  navigate("/");
                }}
              >
                <Home20 />
              </HeaderGlobalAction>

              <HeaderGlobalAction
                aria-label="個人設定"
                onClick={() => {
                  navigate("/profile");
                }}
              >
                <UserFilled20 />
              </HeaderGlobalAction>

              <HeaderGlobalAction aria-label="通知">
                <NotificationFilled20 />
              </HeaderGlobalAction>

              <HeaderGlobalAction
                aria-label="快速導覽"
                onClick={onClickSideNavExpand}
              >
                <Switcher20 />
              </HeaderGlobalAction>
            </HeaderGlobalBar>
          )}

          <HeaderPanel aria-label="切換中心" expanded={isSideNavExpanded}>
            <Switcher aria-label="切換中心">
              <PageSwitcher
                user={user}
                onClickSideNavExpand={onClickSideNavExpand}
              />
            </Switcher>
          </HeaderPanel>
        </Header>
      )}
    />
  );
};

export default CherieHeader;
